<template>
  <div class="advantages-section">
    <h2>Les Fonctionnalités de MiceCloud</h2>
    <div class="advantages">
      <div class="advantage" v-for="(advantage, index) in advantages" :key="index" :data-aos="'fade-up'" :data-aos-delay="index * 100">
        <img :src="advantage.icon" :alt="advantage.alt" />
        <h3>{{ advantage.title }}</h3>
        <p>{{ advantage.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advantages: [
        {
          icon: require('../assets/suivitempsréelcouleur.png'),
          alt: 'Montre connectée',
          title: this.$t('pageMiceCloud.capabilities[0]'),
          description: this.$t('pageMiceCloud.capabilitiesDescription[0]')
        },
        {
          icon: require('../assets/alertecouleur.png'),
          alt: 'Stéthoscope',
          title: this.$t('pageMiceCloud.capabilities[1]'),
          description: this.$t('pageMiceCloud.capabilitiesDescription[1]')
        },
        {
          icon: require('../assets/historiquecouleur.png'),
          alt: 'Cœur avec des feuilles',
          title: this.$t('pageMiceCloud.capabilities[2]'),
          description: this.$t('pageMiceCloud.capabilitiesDescription[2]')
        },
        {
          icon: require('../assets/csvcouleur.png'),
          alt: 'Nuage avec flèches',
          title: this.$t('pageMiceCloud.capabilities[3]'),
          description: this.$t('pageMiceCloud.capabilitiesDescription[3]')
        },
      ]
    };
  },
};
</script>

<style scoped>
.advantages-section {
  text-align: center;
  margin: 50px 0;
  padding: 20px;
  border-radius: 15px;
}

.advantages-section h2{
  margin-bottom: 50px;
}
.advantages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}
.advantage {
  width: 300px;
  padding: 20px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.advantage:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}
.advantage img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.advantage h3 {
  font-weight: 600;
  font-size: 24px;
  color: var(--bleu);
  margin-bottom: 15px;
}
.advantage p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--noirdoux);
}
@media (max-width: 768px) {
  .advantages {
    flex-direction: column;
    align-items: center;
  }
}
</style>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css" />