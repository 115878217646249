<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="5" xl="4" class="image-container">
              <img src="../assets/illustrations/MiceCloud.png" alt="">
      </v-col>
      <v-col cols="12" sm="8" lg="5" xl="5" class="detail">
        <h2>Notre Logiciel MiceCloud</h2>
        <div>
            <p>MiceCloud est une plateforme logicielle dédiée à la surveillance en temps réel de la santé et de la cognition des sujets d'études. Conçue pour les techniciens de laboratoire, elle permet de stocker et visualiser les données collectées par le système MiceTracking. C'est un outil essentiel pour suivre l'état de santé et le comportement de vos sujets
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

.image-container {
  border-radius: 25px;
  display: flex;
  justify-content: center;
}

.image-container img{
  max-width: 90%;
}

.detail {
  overflow: auto; /* Allows the content to scroll if it becomes too large */
  align-content: center;
}

.detail h2{
    padding-left: 30px;
}


.detail div{
    background-color: var(--white);
    border-radius: 40px;
    margin-top: 20px;
    padding:  30px 30px;
}

@media (max-width: 960px) {

.detail{
  margin-top: 50px;
}
.detail p{
  margin-top: 15px;
}

.detail div{
  margin-top: 35px;
}

}
</style>

<script>
  export default {
		name: 'App',
		components: {
		},
	};
</script>